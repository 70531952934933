
function id(id) {
    return document.getElementById(id);
}

function create(el) {
    return document.createElement(el);
}

function text(text) {
    return document.createTextNode(text);
}

function createListItem() {
    let docEl = create('li');
    docEl.classList.add("list-group-item", "d-flex", "justify-content-between");

    return docEl;
}

bcryptButton = id("bcrypt_button");
bcryptInput = id("password");
bcryptHash = id("hash");

passwordsContainer = id("passwords");
passwordsButton = id("passwords_button");

uuidsContainer = id("uuids");
uuidsButton = id("uuids_button");

copyButton = id("copy_button");
copyHelp = id("hashHelp");

function getHash(password) {
    axios.post("https://api.bulldog.dev/bcrypt", {
        password: password
    }).then(res => {
        bcryptHash.value = res.data.hash;
    })
}

function getPasswords() {
    axios.get("https://api.bulldog.dev/passwords").then(res => {
        if(id('password_list')) {
            passwordsContainer.removeChild(id('password_list'));
        }

        let passwordList = create('ul');
        passwordList.classList.add('list-group', 'list-group-flush');
        passwordList.id = 'password_list';

        for(let i=0; i<res.data.passwords.length; i+=2) {
            let el = createListItem();
            let spanOne = create('span');
            let spanTwo = create('span');
            spanOne.classList.add('text-monospace');
            spanTwo.classList.add('text-monospace');
            spanOne.appendChild(text(res.data.passwords[i]));
            spanTwo.appendChild(text(res.data.passwords[i+1]));
            el.appendChild(spanOne);
            el.appendChild(spanTwo);
            passwordList.appendChild(el);
        }
        passwordsContainer.appendChild(passwordList);
    });
}

function getUuids() {
    axios.get("https://api.bulldog.dev/uuids").then(res => {
        if(id('uuid_list')) {
            uuidsContainer.removeChild(id('uuid_list'));
        }

        let uuidList = create('ul');
        uuidList.classList.add('list-group', 'list-group-flush');
        uuidList.id = 'uuid_list';

        for(let i=0; i<res.data.uuids.length; i++) {
            let el = createListItem();
            let spanOne = create('span');
            spanOne.classList.add('text-monospace');
            spanOne.appendChild(text(res.data.uuids[i]));
            el.appendChild(spanOne);
            uuidList.appendChild(el);
        }
        uuidsContainer.appendChild(uuidList);
    });
}

function copyHash() {
    /* Get the text field */
    var copyText = document.getElementById("hash");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    copyHelp.innerText = "Copied!";
}

$(document).ready(function() {
    getHash("password");
    getPasswords();
    getUuids();
});

bcryptButton.onclick = (e) => {
    getHash(bcryptInput.value);
    e.preventDefault();
}

passwordsButton.onclick = (e) => {
    getPasswords();
}

uuidsButton.onclick = (e) => {
    getUuids();
}

copyButton.onclick = (e) => {
    copyHash();
}
